import React from 'react';
import axios from 'axios';
import Header from './Header.jsx';

export default class TemplateManager extends React.Component {

  constructor( props ) {

    super( props );
    this.state = { 
      editId: 0, templates:[] 
    };


    const args = {
      method: 'gettemplates'
    }

    axios.post('/services/services.php', args )
        .then(
          res => {
              this.setState( {templates: res.data.templates} );

          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });

  }

  editCallback( id ) {

    this.setState( state => ({editId: id}) );
  }

  addNewTemplateCallback( id ) {

    this.setState( { editId: id } );
  }

  render() {

    if( this.props.subscriber == 0 ) {

      return(
        <div>
          <div className="bordered">Subscribers can create and edit SMS templates here.</div>
        </div>
      );
    }

    if( this.state.editId == 0 ) {
      return(
        <div>
        <TemplateNew addNewTemplateCallback={this.addNewTemplateCallback.bind(this)} subscriber={this.props.subscriber}/>
        <p/>
        <TemplateList templates={this.state.templates} editCallback={this.editCallback.bind(this)}  />
        </div>
      )
    }
    else {
      return(
        <div>
          <TemplateEditor editId={this.state.editId} />
        </div>
      );
    }
   
    
  }
}

class TemplateNew extends React.Component {
  constructor( props ) {
    super(props);
    this.state = {disableCreateButton: true};

    this.templateName = React.createRef(); 
  }

  validateTemplateName() {
    if( this.templateName.current.value.trim() != "" )
      this.setState( {disableCreateButton: false } );
    else   {
      this.setState( {disableCreateButton: true } );
    }    
  }

  createTemplate() {

    const args= {
      method: 'createtemplate',
      templatename: this.templateName.current.value
    }

    axios.post('/services/services.php', args )
        .then(
          res => {

            if( res.data < 0 ) {
              alert("Something went wrong.");
            }
            else {
              this.props.addNewTemplateCallback( res.data);
            }
            
          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });


  }

  render() { 
    return(
      <div> 
      <Header text="Add a New Template"/>
      <div class='bordered'>
      Once you create a template, you will be able to edit it below.<p/>
      <label>Template Name</label>
      <input ref={this.templateName} onChange={this.validateTemplateName.bind(this)}  type='text' maxlength="32" size="32"/>
      <input type="button" className='button' onClick={this.createTemplate.bind(this)}  disabled={this.state.disableCreateButton} value="Create"/>
      </div>
      </div>
    )
  }


}

class TemplateList extends React.Component {
  constructor( props ) {
    super(props);

  }


  //{React.cloneElement(this.props.children, { cb: this.edited.bind(this) })}
  render() {

    if( this.props.templates.length == 0 ) {
      return(
        <div>
          <header text="Template Management"/>
          <div className='bordered'>Once you create a template, you can edit them here.</div>
        </div>
      )
    } 


    return(
      <div>
      <Header text="Template Management"/>
      {this.props.templates.map( (r) => 
        <div className="flexbox line-item">
          <div className="flex-grow-shrink">
               {r.templateName}
          </div>
          <TemplateControls editCallback={this.props.editCallback} editId={r.id}/>
        </div>
      )}
     </div>
   )
  }
          //<div className="button-box"><a href='#' oncClick={this.edit.bind(this)} className='button'>Edit {r.id}</a> <a href='#' class='button'>Delete</a></div>
}

class TemplateControls extends React.Component {
  constructor( props ) {
    super(props);
    this.state = {};
  }

  edit() { 
    this.props.editCallback( this.props.editId );
  }
  render() {
    return(
      <a onClick={this.edit.bind(this)} href='#'>edit</a>

    )
  }
}

class TemplateEditor2 extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { };
  }

  render() {
    return( 
      <div>
        <MessageEditor/>
      </div>
    );
  }

}


class MessageEditor extends React.Component {

  constructor( props ) {
    super( props );
    this.state = {  message:  "This is a sample message.", mode: 0 }
  }


  render() {

    var parts = this.state.message.split(' ');

    return(
      <div>
        {parts.map( (w) => 
          <Word word={w} />
        )}
      </div>
    )


  }


}


class Word extends React.Component {
  
  constructor( props ) {
    super( props );
    this.state = { column: 0, mode: 0 }
  }

  toggle() {
    this.setState( { mode: 1 } );
  }

  render() {

    
    if( this.state.mode == 0 ) {
      return(
        <div className='word'>
          <div className="text">
          {this.props.word}
          </div> 
        </div>
      )
    }
    if( this.state.mode == 1 ) {
      return(
        <div className='word'>
          <div className='text'>
            <select>
              <option value='1'>Column 1 [asdf]</option>
            </select>
          </div>
        </div>
      )
    }
  }

}















class SampleCSV extends React.Component {

  constructor( props ) {
    super( props );
  }

  render() {
    return(
      <div>
        What does your CSV look like?

        <SampleCSVeditor/>   
      </div>
    ) 
  }

}

class SampleCSVeditor  extends React.Component {
  constructor( props ) {
    super( props );
    this.state = { 
      edit: 0, 
      csv: "330-221-5752,2021-01-01 5pm,test,test2,test3"
     };
  }

  editMode() {
    this.setState( {edit: 1 } );
  }

  render() {

    if( this.state.edit === 0 ) {

      return(
        <div>
          {this.state.csv}
          <input type='button' onClick={this.editMode.bind(this)} label="edit"/>
        </div>
      )
    } 

    if( this.state.edit === 1 ) {

      return(
        <div>
          <textarea>{this.state.csv}</textarea>
          <input type='button' onClick={this.editMode.bind(this)}/>
        </div>
      )
    }

  }
}
  


class TemplateEditor extends React.Component {
  constructor( props ) {
    super(props);
    this.state = { 
      template: "Sample template that inserts column three here: [COLUMN_3]",
      offset:  1,
      deliverImmediately: false,

      columns: [
          {v: "", l: "Column 1", selected: false },
          {v: "", l: "Column 2", selected: false },
          {v: "", l: "Column 3", selected: false },
          {v: "", l: "Column 4", selected: false },
          {v: "", l: "Column 5", selected: false },
          {v: "", l: "Column 6", selected: false },
          {v: "", l: "Column 7", selected: false },
          {v: "", l: "Column 8", selected: false },
          {v: "", l: "Column 9", selected: false }
      ]


      };

    const args= {
      method: 'gettemplate',
      id: this.props.editId
    }

    var self = this;
    axios.post('/services/services.php', args )
        .then(
          res => {

              this.templateText = res.data.template;
              this.setState( state => ({ 
                uniqueIdentifier: res.data.uid,
                template: res.data.template,
                deliverImmediately: res.data.deliverimmediately,
                offset: res.data.offset }) );

            if( res.data.uid != "" ) {
              var UI = this.state.columns;

              var columns = res.data.uid.split(",");

              for( var i = 0; i < columns.length; i++) {
                UI[ columns[i] ].selected = true;
              }
              this.setState( { columns: UI } );
            } 
            
          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });



  }

  uniqueIdentifier = "";
  templateText = "";

  onIdentifierChange( t ) {
    this.setState( {columns: t } );
   // this.uniqueIdentifier = t;
  }

  onTemplateChange( t ) {
    //this.templateText = t;
    this.setState( {template: t} );
  }

  saveTemplate() {


    var identifiers = [];
    for( var i = 0; i < this.state.columns.length; i++) {
      if( this.state.columns[i].selected === true ) {
          identifiers.push( i );
      }
    }

    identifiers = identifiers.join(",");      


    const args= {
      method: 'savetemplate',
      id: this.props.editId,
      //template: this.templateText,
      template: this.state.template,
      uniqueIdentifier: identifiers,
      offset: this.state.offset,
      deliverImmediately: this.state.deliverImmediately 
    }

    var self = this;
    axios.post('/services/services.php', args )
        .then(
          res => {
          
            alert( "Template saved. You can now use this template from the Scheduler. " );

              //this.setState( {templates: res.data.templates} );

          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });


  }

  onCheckChange( cb ) {


    this.setState( state => ({ deliverImmediately: cb.target.checked } ) );

  }
  onOffsetUpdate( hr ) {

    this.setState( state => ({ offset: hr.target.value } ) );
  }
    
  render() {

    if( this.state.template == null ) {
      return(
          <div>Loading</div>
      )
    }
    return(
        <div className='bordered'>
          <Header text="Template Editor"/>
          <TemplateTextArea onTemplateChange={this.onTemplateChange.bind(this)} defaultText={this.state.template}/>
          <TemplateUniqueIdentifier columns={this.state.columns} defaultIdentifier={this.state.uniqueIdentifier} onIdentifierChange={this.onIdentifierChange.bind(this)}/>
          <TemplateTimeOffset onCheckChange={this.onCheckChange.bind(this)} onOffsetUpdate={this.onOffsetUpdate.bind(this)} checked={this.state.deliverImmediately} offset={this.state.offset}/>
          <div>
            See our <a href='implementation-guide.pdf' target="_new">implementation guide</a> for help on
            creating templates and CSVs, and refer to our <a href='test.csv' target="_new">sample CSV</a>
          </div>
          <a href='#' className="button" onClick={this.saveTemplate.bind(this)}>Save</a>
        </div>
    )
  }
 
}

class TemplateTimeOffset extends React.Component {

  constructor( props ) {
    super( props );
    


  }

  render() {

    if( this.props.checked ) 
      return(
        <div>
          <label>Send Immediately</label>
          <input type="checkbox" onChange={this.props.onCheckChange} checked={this.props.checked} /><br/>
        </div>
      )
    else
      return(
        <div>
          <label>Delivery Offset (in hours)</label>
          <input type="text" length="3" maxlength="3" onChange={this.props.onOffsetUpdate} value={this.props.offset}/>
          <div className="info bordered">The <i>delivery offset</i> takes the time from column 2 of your CSV and subtracts
          x hours to create a delivery time.  If your delivery offset is 24 hours, your message will be delivered
          one day before the time listed in column 2 of your CSV.  If your CSV already contains <i>delivery times</i> instead
          of <i>appointment times</i>, this value should be zero.
          </div>        
          <label>Send Immediately</label>
          <input type="checkbox" onChange={this.props.onCheckChange} checked={this.props.checked} /><br/>
          <div className="info bordered">When checked, messages sent with this template will be sent immediately, regardless
          of delivery offset or dates in CSV.
          </div>
        </div>
      )


  } 




}

class TemplateUniqueIdentifier extends React.Component {
  
  constructor( props ) {
    super(props);
    this.templateIdentifierRef = React.createRef()
    this.state = { uniqueIdentifier: this.props.defaultIdentifier 
    }
  }

  returnIdentifier(e) {

    var columns = this.props.columns;
    for( var i = 0; i < e.target.options.length; i++ ) {

      columns[i].selected = e.target.options[i].selected;
    }
    console.log( columns );

    this.props.onIdentifierChange( columns );

  }

  // <input type='text' onChange={this.returnIdentifier.bind(this)} ref={this.templateIdentifierRef} value={this.state.uniqueIdentifier}></input>
  render() {
    return (
      <div>
        <label>Unique Identifier</label>
        <select onChange={this.returnIdentifier.bind(this)} multiple ref={this.templateIdentifierRef}>

          {
            this.props.columns.map( (r) => 
              <option value={r.v} selected={r.selected}>{r.l}</option>
            )
          } 

        </select><br/>
        <i>Hold control to select multiple columns</i>
         
        <div className="info bordered">
          This <i>optional field</i> may be used to  create an <i>unique identifier</i> for messages generated with this template.  These identifiers help identify duplicate 
          message.  Usually this is done by selecting one or more columns from your CSV.  For example, you might choose columns containing a 
          case number and a participant's name, which when combined should be unique.  Please refer to our implementation guid for details.
        </div>
      </div>
    )
  }

}

class TemplateTextArea extends React.Component {

  constructor( props ) {
    super(props);
    this.state = { text: this.props.defaultText };
    this.templateTextRef = React.createRef();
  }

  returnText() {
    this.props.onTemplateChange( this.templateTextRef.current.value );
  }


  render() {
    return(
      <div>
        <label>Template:</label>
        <textarea onChange={this.returnText.bind(this)} alt={this.props.defaultText}   ref={this.templateTextRef} value={this.props.defaultText}></textarea>
        <div className="info bordered">
          Templates are simply messages with placeholders for data from your CSVs.  For example, to insert column three from your CSV, you would
          simply add [column_3] (with the square brackets) in the template above.
        </div>

      </div>
    )
  }


}
 
      

