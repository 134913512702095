import React from 'react';
import axios from 'axios';

export default class SignupForm extends React.Component {

	constructor( props ) {

		super( props );

    this.state = {
      username: "",
      email: "",
      sms: "",
      email: "",
      pass1: "",
      pass2: "",
      terms: false,
      usernameError: true,
      smsError: true,
      emailError: true,
      passwordError: true,
      termsError: true,
      validated: false,
      idConflict: false 
    }


  }


  registerUser() {

      const account = {
      method: 'create',
      username: this.state.username,
      password: this.state.pass1,
      org: "-",
      sms: this.state.sms,
      email: this.state.email,
    }

    axios.post('/services/services.php', account )
        .then(
          res => {
            if( res.data.result  === true ) {
               const account = {
                  method: 'authenticate',
                  username: this.state.username,
                  password: this.state.pass1
                }
                axios.post('/services/services.php', account )
                  .then( res => {
                      console.log( res.data );
                      if( res.data.trim() == "Authenticated" ) {
                        this.props.switchToDashboard();
                      }

                  }, (error) => {
                     this.setState( state => ({ loginError: "Bad username or password." } ) );
                  });
            }
            else 
            {
              if( res.data.message = "id_already_exists" )
                alert("This username already exists.");
              else
                alert("Something went wrong.");
            }

          }

      );


  }

  onUpdate( s, val, validated ) {

    if( s === 'email' )
      this.setState( { 
        email: val,
        emailError: !validated
     } );


    if( s === 'username' )  {
      this.setState( { 
        usernameError: !validated,
        username: val ,
      } );
    }

    if( s === 'terms' ) {
      // alert( validated );
      this.setState( { 
        terms: val,
        termsError: !validated 
      } );

    }
    
    if( s === 'sms' )
      this.setState( { 
        sms: val,
        smsError: !validated 
      } );
    if( s === 'pass1' )
      this.setState( { 
        pass1: val, 
        passwordError: !validated 
      } );
    if( s === 'pass2' )
      this.setState( { 
        pass2: val, 
        passwordError: !validated 
      } );


/*
      console.log( this.state.termsError );
     if( !( !this.state.termsError || this.state.usernameError || this.state.smsError || this.state.passwordError || this.state.emailError ) ) {
      ;
    } 
      else{
        console.log( !this.state.termsError + " " + this.state.usernameError + " " + this.state.smsError + " " + this.state.passwordError + " " + this.state.emailError );
       
      }
*/
  }

  render() {

    let validated = !(this.state.termsError || this.state.usernameError || this.state.smsError || this.state.passwordError || this.state.emailError ) ;


    return(
    
    <div className="login-form fade">
          <SignupTitle/>
          <UsernameField val={this.state.username} onUpdate={this.onUpdate.bind(this)}/>
          <EmailField val={this.state.email} onUpdate={this.onUpdate.bind(this)}/>
          <SmsField val={this.state.sms} onUpdate={this.onUpdate.bind(this)} />
          <PasswordFields val={this.state.pass1} val2={this.state.pass2} onUpdate={this.onUpdate.bind(this)} />
          <Terms val={this.state.terms} onUpdate={this.onUpdate.bind(this)} />
          <SignupButton registerUser={this.registerUser.bind(this)} validated={validated} />
          <ReturnToLogin onShowLoginPage={this.props.onShowLoginPage} />
    </div>

    )

  }

}

class ReturnToLogin extends React.Component {
  constructor( props ) {
    super( props );
  }
  render() {
    return(
      <div>
        Already have an account? <a href='#' onClick={this.props.onShowLoginPage}>Sign in</a>
      </div>
    )
  }
} 

class SignupTitle extends React.Component {
  constructor( props ) {
    super(props );
  }
  render() {
    return(
      <div><h1>Register</h1></div>
    )
  }
}

class ValidationError extends React.Component {
  constructor( props ) {
    super( props );
  }
  render() {
    if( this.props.error === "" )
      return null
    return(
      <div className="validation-error">{this.props.error}</div>
    )
  }
}

class Terms extends React.Component {
  constructor( props ) {
    super( props );
  }

  onChange( e ) {
    this.props.onUpdate( 'terms', e.target.checked, e.target.checked );

  }

  render() {

   
    return( 
      <div>
        <input checked={this.props.val} type='checkbox' onChange={this.onChange.bind(this)}/> I accept the <a href='#'>Terms of Use</a>
      </div>
    )
  }

}
class SignupButton extends React.Component {
  constructor( props ) {
    super( props );
  }


  render() {
      return(
        <div>
          <input type='button' disabled={!this.props.validated} onClick={this.props.registerUser} className='button sign-in-button' value='Register'/>
        </div>
      )
    }
}

class UsernameField extends React.Component {
  constructor( props ) {
    super( props );
    this.state = { 
      error: "User name may not be blank",
      failed: 1 
    };   
  }

  onChange( e ) {

    var validated = true;

    var u = e.target.value.trim();

    this.error = "";

    if( u.length == 0 ) {
      this.error = "User Name may not be blank";
      validated = false;
    }
    else {
      this.error = "";
    }

    this.props.onUpdate( 'username', u, validated );


    const account = {
      method: 'checkid',
      username: u,
    }
    axios.post('/services/services.php', account )
      .then( res => {
        if( res.data.exists === true ) {
          this.setState({error:  "User name already exists."});
          this.props.onUpdate( 'username', u, false );
        } else {
          this.setState( {error: "" } );
        }
    }, (error) => {
      // for now, don't do anything if we can't check if username
      // already exists.  it'll get caught on submit anyway
    });



  }
    

  render() {
    return( 

      <div>
        <label>User Name</label>
        <input onChange={this.onChange.bind(this)} type='text' className='login-form-input' value={this.props.val}/>
        <ValidationError error={this.state.error}/>
      </div>
    )
  }
}


class PasswordFields extends React.Component {
  
  constructor( props ) {
    super( props );
    this.pass1 = "";
    this.pass2 = "";
    this.error = "";
  }

  onChange1( e ) {
    this.pass1 = e.target.value;

    var minLength =  this.pass1.length >= 8;  
    var mismatch = this.pass2 == this.pass1;

     var passwd=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
     if( !this.pass1.match(passwd))
        passwd = false;
      else
        passwd = true;
    this.props.onUpdate('pass1', this.pass1, mismatch && minLength && passwd);

  }

  onChange2( e ) {

    this.pass2 = e.target.value;

    var minLength =  this.pass2.length >= 8;  
    var mismatch = this.pass2 == this.pass1;
    this.props.onUpdate('pass2', this.pass2, mismatch && minLength);
 
  } 

  render() {

    if( this.pass1 != this.pass2 ) 
      this.error = "Password mismatch.";
    else
      this.error = "";

     var passwd=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
     if( !this.pass1.match(passwd))
        passwd = false;
      else
        passwd = true;


    if( this.pass1.length < 8 || passwd == false ) {
      this.error = "Password must be 8 to 16 characters which contain at least one numeric digit and a special character: ! @ # $ % ^ & *";



    }

 
    return(
      <div>
        <label>Password</label>
        <input onChange={this.onChange1.bind(this)} className='login-form-input' type='password' value={this.props.val}/>
       
        <label>Confirm Password</label>
        <input onChange={this.onChange2.bind(this)} className='login-form-input' type='password' valuie={this.props.val2}/>
        <ValidationError error={this.error}/>
      </div>
    )
  }

}
class SmsField extends React.Component {

  constructor( props ) {
    super( props );
    this.error = "";
  }

  onChange( e ) {

    var validated = true;

    var sms = e.target.value;

    sms = sms.replace(/-/g, "");
    sms = sms.replace(/\D/g, "");
    sms = sms.replace("(", "");
    sms = sms.replace(")", "");

    if( sms.length > 3 ) 
      sms = sms.slice(0,3) + "-" + sms.slice(3);
 
    if( sms.length > 7 ) 
      sms = sms.slice(0,7) + "-" + sms.slice(7);

    if( sms.length > 12 )
      sms = sms.substring(0,12);

    if( sms.length != 12 )
      validated = false;

    
    this.props.onUpdate( 'sms', sms, validated ); 
   
  }

  render() {

    if( this.props.val.length != 12 )
      this.error = "Valid SMS number required";
    else
      this.error = "";

    return (
      <div>
        <label>SMS / Cell</label>
        <input onChange={this.onChange.bind(this)} className='login-form-input' type='text' value={this.props.val}/>
        <ValidationError error={this.error}/>
      </div>

    )

  }
}

class EmailField extends React.Component {
  constructor( props ) {
    super( props );
    this.error = "";
  }

  onChange( e ) {

    var email = e.target.value.trim();

    var validated = email.length >= 1;
   
    if( validated )
      console.log("Email validated");
 
    this.props.onUpdate( 'email', email, validated );

  }

  render() {

    if( this.props.val.indexOf("@") == -1 ) 
      this.error = "Valid e-mail required.";
    else
      this.error = "";

    return(
      <div>
        <label>E-Mail</label>
        <input onChange={this.onChange.bind(this)} className='login-form-input' type='text' value={this.props.val}/>
        <ValidationError error={this.error}/>
      </div>
    )
  }

}




