import React from 'react';

export default class Header extends React.Component {

	constructor( props ) {

		super( props );

  }

  render() {

    if( this.props.preface == null ) {
      return( 
        <div className="header">
          <div className="header-text">{this.props.text}</div>
        </div>
      );
    }

    return(
      <div className="header">
        <div className="header-preface">{this.props.preface}</div>
        <div className="header-text">{this.props.text}</div>
      </div>
    );
  }

}

