import logo from './logo.svg';
import React, {Component} from 'react';
import { Grid, Cell, Button, Colors, Link, Tabs, TabItem, TabPanel, TabsContent } from 'react-foundation';
import axios from 'axios';

import TM2 from './components/TemplateManager.jsx';
import LoginForm from './components/Login.jsx';
import SignupForm from './components/Signup.jsx';
import Header from './components/Header.jsx';
import AdminUI from './components/AdminUI.jsx';

import './App.css';

class App extends React.Component  {

  constructor( props) {
    super(props);
    this.state = {
      form: 'login'
    } 

   
  }

  render() {
    return (
      <div className="App">
        <FrontLayout form={this.state.form}/>
      </div>
    );
  }
}


class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
  }

  logout() {

    axios.get('/services/logout.php')
        .then(
          res => {
            window.location.href="/";
          }
      ).catch(
        error => {
          if( error.response) {
            console.log("couldnt logut");
          }
        }
      );




  }

  render() {
    return (
    <li><a href='#' onClick={this.logout} ><i className="fa fa-sign-out" aria-hidden="true"></i></a></li>
    );
  }

}

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  gotoSettings() {

    this.props.changeRoute("settings");

    //alert( this.props.changeRoute );
  }  
  render() {

    return (
      <li><a href='#' onClick={this.gotoSettings.bind(this)}><i className='fas fa-cog' aria-hidden="true"></i></a></li>
    )

  }

}


class FrontLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      mode: 'login',
      showSignUp: false

      };
    // this.state = { mode: 'dashboard'};

  }
  switchToDashboard() {
    this.setState( state => ({ mode: 'dashboard'} ) );
  }

  onShowLoginPage() {
    this.setState( {showSignUp: false } );
  }

  onShowSignupPage() {
    // alert("show signup");
    this.setState( {showSignUp: true} );
  }

  render() {
    console.log( this.state );


    if( this.state.mode == 'dashboard') 
       
      return(
        <DashBoard/>
      );
 
  if( this.state.showSignUp == true ) 
	return(
		<div className='login-container2'>
			<div className='login-box2'>
				<div className="login-left">
          <SignupForm switchToDashboard={this.switchToDashboard.bind(this)} onShowLoginPage={this.onShowLoginPage.bind(this)}/>
				</div>
        <div className="login-right"></div>
			</div>
    </div>
  );

    if( this.state.mode == 'login')

	return(
		<div className='login-container2'>
			<div className='login-box2'>
				<div className="login-left">
					<LoginForm switchToDashboard={this.switchToDashboard.bind(this)} onSignup={this.onShowSignupPage.bind(this)}/>
				</div>
				<div className="login-right">
				</div>
			</div>
		</div>
	)
  }

}

class DashBoard extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      form: this.props.form, 
      groupid: 0, // set to 0
      sms: 2125551212,
      route: 'smsScheduler',
      subscriber: 0,
      admin: 0,
      counter: 0
    };


    // this appears to be set twice.

    axios.get('/services/props.php')
        .then(
          res => {
            var gsms = res.data.groupsms.substring(0,3) + "-" + res.data.groupsms.substring(3,6) + "-" + res.data.groupsms.substring(6);
            var mysms = res.data.sms;
            this.setState( state => ({ groupid: res.data.groupid, groupsms: gsms, sms: mysms, subscriber: res.data.subscriber, admin: res.data.admin }) );

          }
      ).catch(
        error => {
          if( error.response) {
            console.log("couldnt get props");
          }
        }
      );


  }

  changeRoute( r ) {
    this.setState( state=> ({ route: r, counter: this.state.counter++} ) );
  }
  render() {

    if( this.state.route === "logs" ) 
    return( 
    <div className="grid-x grid-padding-x grid-padding-y">
      <MenuBar changeRoute={this.changeRoute.bind(this)} />

      <div className="cell layout-parent">
        <div className="layout-container grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y">
          <div className="cell small-12"><Sidebar admin={this.state.admin} horizontal={true} changeRoute={this.changeRoute.bind(this)}/></div>
          <div className="channel wide cell small-12">
             <Router counter={this.state.counter} subscriber={this.state.subscriber} route={this.state.route} groupsms={this.state.groupsms} sms={this.state.sms} groupid={this.state.groupid} changeRoute={this.changeRoute.bind(this)}  />
          </div>
        </div>
      </div>
    </div>
    );


    return( 
    <div className="grid-x grid-padding-x grid-padding-y">
      <MenuBar changeRoute={this.changeRoute.bind(this)}/>
      <div className="cell layout-parent">
        <div className="layout-container grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y">
          <div className="cell small-12 medium-3 large-3"><Sidebar admin={this.state.admin} changeRoute={this.changeRoute.bind(this)}/></div>
          <div className="channel cell small-12 medium-9 large-6">
            <Router counter={this.state.counter} subscriber={this.state.subscriber} route={this.state.route} groupsms={this.state.groupsms} sms={this.state.sms} groupid={this.state.groupid} changeRoute={this.changeRoute.bind(this)}  />
          </div>
          <div className="cell small-12 medium-1 large-1"></div>
        </div>
      </div>
    </div>
    );

  return(<div>hey</div>);
  }

}


class MOTD extends React.Component {
  constructor( props ) {
    super( props );
  }
  render() {
  
    if( this.props.subscriber )
      return( null  );

    else
        return( <div className="templatetext info">Thanks for trying our 720 Reminder service. <a href="mailto:pmw@nuncworks.com">Contact our implementation team</a> for more information and pricing</div> );

  }
}


class Router extends React.Component {
  constructor( props ) {
    super( props );
    // this.state  = { route: "smsScheduler"};
  }
  render() {

    if( this.props.route  == "smsScheduler") {
      return(
         <Channel title="720 Reminder">
          <MOTD subscriber={this.props.subscriber}/>
          <p/>
          <SmsManager counter={this.props.counter} subscriber={this.props.subscriber} groupid={this.props.groupid} gsms={this.props.groupsms} mysms={this.props.sms}/>
        </Channel>
      );
    }

    if( this.props.route == "templates") {
      return( <Channel title="Templates">
        <TM2 subscriber={this.props.subscriber}/>
        </Channel>
      );
    }

    if( this.props.route == "settings") {
      return( <Channel title="Settings">
        <UserSettings/>
        </Channel>
      );
    }

    if( this.props.route == "admin") {
      return(
        <Channel title="Administration">
          <AdminUI/>
        </Channel>
      );
    }

    if( this.props.route == "logs" ) {
      return(
        <Channel wide='true' title="SMS Reports">
        <SMSLogs/>
        </Channel>
      ); 
    } 
    return( <div>Router was given an unimplemented route, {this.props.route} </div> ); 

  }

}




class UserSettings extends React.Component {

  constructor( props ) {

    super(props);
    this.state = {};

  }

  render() {
    return(
      <div>
        <Credits micro='false'/>
        <TimeZone/>
        <PasswordChange/>
      </div>
    )
  }

}

class TimeZone extends React.Component {

  constructor(props) {
    super( props );

    this.state = { 
      tzid: 0, 
      timezones: null
    };

    this.tzList = ["America\/Adak","America\/Anchorage","America\/Boise","America\/Chicago","America\/Denver","America\/Detroit","America\/Indiana\/Indianapolis","America\/Indiana\/Knox","America\/Indiana\/Marengo","America\/Indiana\/Petersburg","America\/Indiana\/Tell_City","America\/Indiana\/Vevay","America\/Indiana\/Vincennes","America\/Indiana\/Winamac","America\/Juneau","America\/Kentucky\/Louisville","America\/Kentucky\/Monticello","America\/Los_Angeles","America\/Menominee","America\/Metlakatla","America\/New_York","America\/Nome","America\/North_Dakota\/Beulah","America\/North_Dakota\/Center","America\/North_Dakota\/New_Salem","America\/Phoenix","America\/Sitka","America\/Yakutat","Pacific\/Honolulu"];


    const method = {
      method: 'gettimezones',
    }
    axios.post('/services/services.php', method)
        .then(
          res => {
            this.setState( {timezones: res.data.zone } ); 
            // this.setState( {tzid:  res.data.id } );
          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });
 
        
    const account = {
      method: 'gettimezone',
    }
    axios.post('/services/services.php', account )
        .then(
          res => { 
            this.setState( {tzid:  res.data.id } );
          } 
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });



  }

  onTimeZoneChange( e ) {
    this.setState( { tzid: e.target.options[ e.target.options.selectedIndex ].value } );
  }

  saveTimeZone( e ) {
    const account = {
      method: 'settimezone',
      tz: this.state.tzid 
    }
    axios.post('/services/services.php', account )
        .then(
          res => { 
            this.setState( {tzid:  res.data.tz } );
            alert("saved.");
          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });

 
  }

  render() {

    console.log( this.state.timezones );
    if( this.state.timezones == null )
      return( null );
    return(
      <div>
        <Header text="Set Time Zone"/>
        <div className="bordered">
          For scheduling purposes, select your time zone.
          <br/> 
          <select onChange={this.onTimeZoneChange.bind(this)}>
        
            {this.state.timezones.map( (t) => (  
              t.id === this.state.tzid ? 
              <option selected='true' value={t.id}>{t.timezone}</option> : <option value={t.id}>{t.timezone}</option>
             
              ) 
            )}
          </select><br/>
          <input type='button' className='button' onClick={this.saveTimeZone.bind(this)} value='Save Time Zone'/>
        </div>
      </div>
    )
  }

}

  

class Credits extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {credits: null }

    const account = {
      method: 'getcredits',
    }

    var self = this;
    axios.post('/services/services.php', account )
        .then(
          res => {
              this.setState( { credits: res.data} )
           }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });
 
  }

  render() {

    if( this.props.micro ===  'true' ) 
    {
      if( this.state.credits != null ) {
        return(
          <li><a class='credit-button' href='#'><i class="fas fa-dot-circle"></i> {this.state.credits} Credits</a></li>
        )
      }
      else
        return( null );
    }
    if( this.state.credits == null ) {
      return( 
        <div>Loading credits...</div>
      )
    }

    return(
      <div className="bordered fade">
        <Header text="Credits"/>
          <b>Credits: {this.state.credits}</b>
        <div>Credits are used by subsribers to send messages.  Credits are deducted at delivery time.
          Guest users with limited functionality do not require credits for testing purposes. 
        </div>
        
      </div>
    )

  }
}



class PasswordChange extends React.Component {

  constructor( props ) {
    super(props);
    this.state = { pwdDisabled: true};
    this.p1 = React.createRef(); 
    this.p2 = React.createRef(); 
    this.errorText = React.createRef();
//    this.checkPassCriteria();
  }

  checkPassCriteria() {

    if( this.p1.current.value != this.p2.current.value ) {
      this.errorText.current.innerHTML = "Passwords do not match.";
      this.setState( { pwdDisabled: true }); 
      return;
    }
    var paswd=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    if( !this.p1.current.value.match(paswd))
    {
      this.errorText.current.innerHTML = "Password must be 8 to 16 characters which contain at least one numeric digit and a special character: ! @ # $ % ^ & *";
      this.setState( { pwdDisabled: true }) 
      return;
    }

    this.errorText.current.innerHTML = "";
    this.setState( { pwdDisabled: false}); 


  }

  updatePassword() {

    const account = {
      password: this.p1.current.value,
      method: 'updatepassword',
    }

    var self = this;
    axios.post('/services/services.php', account )
        .then(
          res => {
            if( res.data.trim() === "Updated" ) {
              alert("Updated :D");
              this.p1.current.value = "";
              this.p2.current.value = "";
              this.setState( { pwdDisabled: true }) 
            }
            else
              alert("Something went wrong.");

          }
        ).catch( function( error ) {
          if( error.response.status === 401 )
            window.location.href = "/logout.html";
        });


  }

  render() {

    return(
      <div>
        <Header text="Change Password"/>
        <div className="bordered">
          <div>New Password</div>
          <input ref={this.p1} type="password" onChange={this.checkPassCriteria.bind(this)}/>
          <div>New Password (again)</div>
          <input ref={this.p2} type="password" onChange={this.checkPassCriteria.bind(this)}/>
          <div ref={this.errorText}></div>
          <input className="button" type="button"  disabled={this.state.pwdDisabled}  onClick={this.updatePassword.bind(this)} value="Update Password"/>
        </div>
      </div>
    );
  }
}



class SMSLogs extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { records:  [], filter: null  };

    const args = {
      method: 'smslogs'
    }

    // this.props.changeRoute('logs');

    axios.post("services/services.php", args).then(
      res => {

        function zeroPad(num, places) {
          var zero = places - num.toString().length + 1;
          return Array(+(zero > 0 && zero)).join("0") + num;
        }

        for( var x = 0; x < res.data.records.length; x++ ) {

          var d = new Date( res.data.records[x].timestamp * 1000 );

          res.data.records[x].timestamp = d.getFullYear() + "-" + (d.getMonth()+1)  + "-" + d.getDate() + " " + d.getHours() + ":" + zeroPad(d.getMinutes(),2) + ":" + zeroPad(d.getSeconds(),2);

          d = new Date( res.data.records[x].deliverytime * 1000 );
          res.data.records[x].deliverytime = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate()  + " " + d.getHours() + ":" + zeroPad(d.getMinutes(),2) + ":" + zeroPad(d.getSeconds(),2);


          if( d != null ) {
            d = new Date( res.data.records[x].deliveredat * 1000 );
            res.data.records[x].deliveredat = d.getFullYear() + "-" + (d.getMonth()+1) + "-" + d.getDate()  + " " + d.getHours() + ":" + zeroPad(d.getMinutes(),2) + ":" + zeroPad(d.getSeconds(),2);
          }
        }
        this.setState( { records: res.data.records });  
    }).catch( function( error ) {
      if( error.response.status === 401 )
        window.location.href = "/logout.html";
    });

  }

  cfCallback( hash ) {

    this.setState( state => ({ filter: hash } ) );

  }
  render() {

  var hashes = [];

   this.state.records.forEach( r => {
      if( r.hash == null ) { 
        hashes[ r.hash ] = -1;
      }
      else {
        if( hashes[ r.hash ] == null )
          hashes[ r.hash ] = 1;
        else
          hashes[ r.hash ]++;
      }
    });


    if( this.state.records.length == 0 ) {
      return(
        <div><b>No logs found.</b></div>
      )
    }
    var records = this.state.records;
    if( this.state.filter != null ) {
      records = records.filter( r => r.hash == this.state.filter );
    }


    return(
      <div>
        <div className="logLines">
          <div>ID:</div> 
          <div>To:</div>
          <div>Submitted:</div>
          <div>Scheduled:</div>
          <div>Processed:</div>
          <div>Attempted:</div>
          <div>Completed:</div>
          <div>Error:</div>
          <div>Cost:</div>
          <div>Conflict:</div>
          <div>Delete:</div>
        </div>
        {records.map( (r )  =>
        <React.Fragment>
          <div className='logLines'>
            <div >{r.id}</div>
            <div>{r.sms}</div>
            <div>{r.timestamp }</div>
            <div>{r.deliverytime }</div>
            <div>{r.deliveredat }</div>
            <div>{r.attempted == true ? "attempted" : "queued"}</div>
            <div>{r.completed == true ? "completed" : "-"}</div>
            <div>{r.error == true ? "error" : "-"}</div>
            <div>{r.cost}</div>
   <div><ConflictFilter count={hashes[r.hash]} hash={r.hash} conflictFilterCallback={this.cfCallback.bind(this)}/></div>
            <div><DeleteButton messageId={r.id}/></div>
          </div>
          <div className='messageLine'>
            <div>{r.message}</div>
          </div>
        </React.Fragment>
      )}
      </div>
    )
  }

}

class DeleteButton extends React.Component {

  constructor( props ) {
    super( props );
    this.state = {deleted: false };
  }

  delete() {
    alert("Deleting");
    this.setState( state=> ({ deleted: true}) );

    const args = {
      method: 'deletemessage',
      id: this.props.messageId
    }

    axios.post("services/services.php", args).then(
        res => {
          this.setState( state=> ({ deleted: true}) );
        }
    ).catch( function( error ) {
      if( error.response.status === 401 )
        window.location.href = "/logout.html";
    });
 

    // maybe needs a callback...
  }

  render() {
    if( this.state.deleted == false ) {
      return( 
        <a href='#' className='button small' onClick={this.delete.bind(this)}>delete</a>
      )
    }
    else {
      return( 
        <a href='#' className='button small hollow' disabled='true'>deleted</a>
      );
    }
  }
}
  

class ConflictFilter extends React.Component {
  constructor( props ) {
    super( props );
  }

  callback() {
    this.props.conflictFilterCallback( this.props.hash );
  }

  render() {
    if( this.props.count == -1 )
      return( <a href='#' className='button small disabled'>unknown</a> );
    if( this.props.count < 2 )
      return(null);
    else
      return(<a href='#' className='button small' onClick={this.callback.bind(this)}>conflict</a>);
  }

}


class Sidebar extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {};
  }

  showLogs() {
    this.props.changeRoute('logs');
  }
  showScheduler() {
    this.props.changeRoute('smsScheduler');
  } 
  showTemplates() {
    this.props.changeRoute('templates');
  }
  showAdmin() {
    this.props.changeRoute('admin');
  }


  render() {


    if( this.props.horizontal == true ) 
    return(
      <div className='grid-x'>
      <div className="cell small-12">
      <ul className="menu">
      <li className="menu-text">720 Reminder</li>
      <li><a href='#' onClick={this.showScheduler.bind(this)}>Scheduler</a></li>
      <li><a href="#"  onClick={this.showLogs.bind(this)}>Reports</a></li>
      <li><a href="#"  onClick={this.showTemplates.bind(this)}>Templates</a></li>
      { Boolean( this.props.admin !== true) ? null :  <li><a href='#' onClick={this.showAdmin.bind(this)}>Admin</a></li> }
      </ul>
      </div>
      </div>
    );

    return(
      <div className='grid-x'>

      <div className="cell small-12 hide-for-medium">
      <ul className="menu">
      <li className="menu-text">720 Reminder</li>
      <li><a href='#' onClick={this.showScheduler.bind(this)}>Scheduler</a></li>
      <li><a href="#"  onClick={this.showLogs.bind(this)}>Reports</a></li>
      <li><a href="#"  onClick={this.showTemplates.bind(this)}>Templates</a></li>
      { Boolean(this.props.admin) && <li><a href='#' onClick={this.showAdmin.bind(this)}>Admin</a></li> }
      </ul>


      </div>


      <div className="cell hide-for-small-only medium-12">
      <ul className="vertical menu">
      <li className="menu-text">720 Reminder</li>
      <li><a href='#' onClick={this.showScheduler.bind(this)}>Scheduler</a></li>
      <li><a href="#"  onClick={this.showLogs.bind(this)}>Reports</a></li>
      <li><a href="#"  onClick={this.showTemplates.bind(this)}>Templates</a></li>
      { Boolean(this.props.admin) && <li><a href='#' onClick={this.showAdmin.bind(this)}>Admin</a></li> }
      </ul>
      </div>

      </div>
    );

  }

}


class Channel extends React.Component {

  constructor( props ) {
    super( props );
    this.state = {};
  }

  render() {

    return(
     <div>
       <div className="grid-x">
         <div className='channel-title'>{this.props.title}</div>
        </div> 
      {this.props.children}
    </div>
    )


  }

}






class SmsManager extends React.Component {


  constructor( props ) {
    super( props );
    this.state = { 
      templates: null, 
      selectedTemplateText: null,
      templatesLoaded: false,
      showTemplatePicker: 0,
      showTemplateUploader: 0,
      selectedId:  -1,
      confirmed: 0
    };
    
    const args = { method: 'gettemplates' }

    axios.post("services/services.php", args).then(
        res => {
          // this.props.onTemplateChange( res.data.templates[0].id );
          this.setState( state => ({
              templates: res.data.templates,
              selectedTemplateText: res.data.templates[0] == null ? "" : res.data.templates[0].template,
              templatesLoaded: true,
              showTemplatePicker: 1
            }) );
        }
    ).catch( function( error ) {
      if( error.response.status === 401 )
        window.location.href = "/logout.html";
    });

  } 

  onCancel() {
    this.setState( {confirmed: 0, selectedId: -1, confirmed:0, selectedTemplateText: 0, showTemplateUploader: 0 } );
  }

  onTemplateSelected( id ) {
    this.setState( { selectedId: id } ); 
  }
/*
  render() {
    return( <SmsScheduler templates={this.state.templates} templatesLoaded={this.state.templatesLoaded} subscriber={this.props.subscriber} groupid={this.props.groupid} gsms={this.props.gsms} sms={this.props.mysms} /> ); 
    // return( <SmsManagerDemoScheduler subscriber={this.props.subscriber} groupid={this.props.groupid} gsms={this.props.gsms} sms={this.props.mysms} />) 
  } */

  onTemplateConfirmed( x ) {
    this.setState( {confirmed: 1 } );

  }

  render() {
      if( this.state.confirmed == 1  ) {
        
        return( <SmsManagerDemoScheduler counter={this.props.counter} onCancel={this.onCancel.bind(this)} templateId={this.state.selectedId} subscriber={this.props.subscriber} groupid={this.props.groupid} gsms={this.props.gsms} sms={this.props.mysms}/> )
      }

      if( this.state.templatesLoaded ) {

        return( <TemplatePicker selectedId={this.state.selectedId} onTemplateSelected={this.onTemplateSelected.bind(this)}  onTemplateConfirmed={this.onTemplateConfirmed.bind(this)}  templates={this.state.templates} templatesLoaded={this.state.templatesLoaded} subscriber={this.props.subscriber} groupid={this.props.groupid} groupsms={this.props.gsms} sms={this.props.mysms} /> );
      }

    return("loading....");
  }
}


class TemplatePicker extends React.Component {
  constructor( props ) {
    super( props );


   
  }

  templateChange( e ) {

    this.props.onTemplateSelected( e.target.value  );
    //this.props.onTemplateSelected( e.target.selectedIndex -1 );
    /* this.setState( {selectedIndex: e.target.selectedIndex -1,
      selectedTemplateText: this.props.templates[ e.target.selectedIndex -1  ].template
    }); */

  //   this.props.onTemplateChange( e.target.options[ e.target.selectedIndex ].value );

  }


  render() {

    if( this.props.templates.length == 0 )
      return( 

      <div>
        <div className="bordered">Before you can schedule a message, you need to create a template.</div>
        <TM2/>
      </div> 


      );


    // var template = this.props.templates[ this.state.selectedIndex].template; 

    var selectedTemplateText = "";
    for( var x = 0; x < this.props.templates.length; x++ ) {

      if( this.props.templates[x].id ==  this.props.selectedId ) 
        selectedTemplateText = this.props.templates[x].template; 
    }
   

    // this needs to be adjusted -- remember we're passing in the id, so we need to find that... 
    //const selectedTemplateText = 'fix me '; // this.props.templates[ this.props.selectedTemplateIndex ];

    return(
      <div>

        <Header preface="Step 1" text="Choose A Message Template"/> 
        <div className="template bordered fade">
          <label>Available Templates:</label>
          <select onChange={this.templateChange.bind(this)} dummy={this.props.selectedIndex}  >
            <option value="0" disabled selected>Choose a template to begin</option>
            {this.props.templates.map((t) =>
              <option value={t.id}>{t.templateName}</option> 
            )}
          </select>
          <TemplateHelper subscriber={this.props.subscriber}/>

        <TemplateContents onTemplateConfirmed={this.props.onTemplateConfirmed} selectedId={this.props.selectedId} selectedTemplateText={selectedTemplateText} />
        </div>

      </div>
    )



  }


} 

class TemplateHelper extends React.Component {
  constructor( props ) {
    super(props);
  }
  render() {
    if( this.props.subscriber === "false" ) {
      return(
        <div class='bordered info'>Nuncworks combines <i>templates</i> with CSVs to craft your messages.  Templates are simply
        messages with <i>placeholders</i> which are replaces with columns from your CSV.  We have provided
        a few demonstration templates for your convenience.</div>
      )
    }
    else {
      return(
        <div class='bordered info'>Nuncworks combines <i>templates</i> with CSVs to craft your messages.  Templates are simply
        messages with <i>placeholders</i> which are replaces with columns from your CSV.</div>
      )
    }
  }
} 


class TemplateContents extends React.Component {
  constructor( props ) {
    super( props );
  }
  confirm() {
    this.props.onTemplateConfirmed( this.props.selectedId );
  }
  render() {
      if( this.props.selectedId == -1 )
        return null;

     
    return (
      <div className="fade">
        <label>Template Preview:</label>
        <div className='templatetext'>{this.props.selectedTemplateText}</div>
        <div className="bordered info">We will use the selected template for your messaging.  Fields like [COLUMN_3] will be replaced
          with data from your CSV, which we will gather in the next step.</div>
        <div className="buttons-align-right"><input className="button" type='button' onClick={this.confirm.bind(this)} value="Use this template"/></div>
      </div>
    )
  }
}


class SmsManagerDemoScheduler extends React.Component {
  constructor( props ) {
    super( props );
    this.state = {
      renderedcsv: '',
      csv: [],
      sent: false,
      lineCount: 0,
      cost: '-',
      counter: this.props.counter,
      previewDisabled: true // this doesn't actually do anything.  
    };
    this.fileRef = React.createRef();

  }


    onFileChange = event => {
    
      // Update the state
      this.setState({ selectedFile: event.target.files[0], previewdisabled: false });
   
    };

  onFileUpload( andSchedule) {



      const formData = new FormData();
    
      // Update the formData object
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );


      formData.append('template','demo');

      if( andSchedule === "send") {
        formData.append('send', true);
      }
      else {
        formData.append('send', false);
      } 
      formData.append('templateID', this.props.templateId ); 
      // Details of the uploaded file

      axios.post("services/upload.php", formData).then(

        res => {
        /*
          this.setState( state => ({ csv: res.data.lines}));
          this.setState( state => ({ errorCount: res.data.errorCount }));
          this.setState( state => ({ lineCount: res.data.lineCount }));
          this.setState( state => ({ sent: true && andSchedule === "send"} ));
      */
          this.setState( state => ({ 
            csv: res.data.lines,
            cost: res.data.cost,
            errorCount: res.data.errorCount, 
            lineCount: res.data.lineCount, 
            sent: true && andSchedule === "send"} 
          ));
          // i fdont remember what that was trying to accomplish ^^
        }
      ).catch( function( error ) {
        if( error.response.status === 401 )
          window.location.href = "/logout.html";
      });


  }


  ApproveAndSchedule() {

    this.onFileUpload( "send" );
  }
 
  ResetState() {

    this.props.onCancel();
    //this.setState( state => ({ sent: false, renderedcsv: '', csv:[],   lineCount: 0 }) );

    // this should do more though, like clear the file uploader
  } 



//            <DeliveryMethodInfo subscriber={this.props.subscriber} sms={this.props.sms} gsms={this.props.gsms}/>



  render() {
    const csv = this.state.csv;
    
    if( this.state.sent == false ) { 
      if( this.state.lineCount > 0 ) {
          return(
                <CSVconfirm subscriber={this.props.subscriber} cost={this.state.cost} onCancel={this.ResetState.bind(this)} csv={csv} errorCount={this.state.errorCount} lineCount={this.state.lineCount} approveAndSchedule={this.ApproveAndSchedule.bind(this)}/>
          );
      }

      
      return(


          <div className="cell small-12 medium-9">

            <Header preface="Step 2" text="Upload a CSV"/>
            <div className="bordered template fade">
              
              <div className="grid-x">
                <div className="small-12">
                  <label>Click the browse button below to upload a CSV from your computer.</label>
                  <input type="file" ref={this.fileRef} onChange={this.onFileChange.bind(this)}/>
                </div>
                <div className="small-12 bordered">
                  We will merge your selected template with the contents of your CSV.  You will
                  have the opportunity to reivew your messaging before scheduling your message for delivery.
                </div>
                  
                <div className="small-12"> 
                  <div className="buttons-align-right">
                    <input type="button" className="button hollow" onClick={this.props.onCancel} value="Cancel"/>
                    <input type="button" className="button" onClick={this.onFileUpload.bind(this)} value="Preview"/> 
                  </div>
                </div>
              </div>
            </div>

              <div>
                See our <a href='implementation-guide.pdf' target="_new">implementation guide</a> for help on
                creating templates and CSVs, and refer to our <a href='test.csv' target="_new">sample CSV</a> 
              </div>
 
          </div>


    );
  }
    if( this.state.sent == true ) { 
    return( 

      <div className="cell small-12 medium-9">

        <Header text="Messages Queued!"/>
        <b>We've queued messages for you.</b>
        <p/>
        <div className='templatetext'>
          {csv.map((line) =>
          <div>
            <div className='csv'><b>{line.sms}</b>: {line.rendered}</div>
            <div className='csv-error'>{ line.error == 0 ? "" : "^ ^ ^ ^ Error:" } {line.errorText}</div>
          </div>
          )}
        </div>

        <div className='buttons-align-right'> 
        <input type="button" className="button" onClick={this.ResetState.bind(this)} value="Schedule Additional Messages"/>
        </div>
      </div>
);

    }

  }

            //<div>{this.state.renderedcsv}</div>
}


class DeliveryMethodInfo extends React.Component {
  constructor( props ) {
    super( props );
  }
  render() {  
    if( !this.props.subscriber ) {
        return(
           <div>Messages will be sent from <b>{this.props.gsms}</b> and delivered to <b>{this.props.sms.substring(0,3)}-{this.props.sms.substring(3,6)}- {this.props.sms.substring(6)}</b>.<p/>
            <b>Up to three messages</b> will be delivered using the default rule, which is <b>immediately.</b>
          </div>

      )
      } else {

        return(
          <div> Messages will be sent from <b>{this.props.gsms}</b></div>
        )
      }
  }
}
   


class CSVconfirm extends React.Component {
    constructor( props ) {
      super( props );
      this.state = {};
    }


  render() {

    if( this.props.csv.length > 0 ) {
       
        return(   
          <div>
            <div>
              <Header preface="Step 3" text="Review and Schedule"/>

              <div className="bordered fade">
              
                A preview of your messages follows below.  If you're happy 
                with the message, you may now approve the message to schedule
                it for delivery.
              </div> 
              <label>Message Preview</label>
              <div className='templatetext fade'>
                {this.props.csv.map((line) =>
                <div> 
                  <div className='csv'><b>{line.sms}</b>: {line.rendered}</div>
                  <div className='csv-error'>{ line.error == 0 ? "" : "^ ^ ^ ^ Error:" } {line.errorText}</div>
                </div>
                )}
                <div className='bordered info'>
                  Total Messages:  {this.props.lineCount}
                  <p/>

                  { this.props.errorCount == 0 ? "No errors found." : this.props.errorCount + " errors found.  These lines will be ignored if you choose to continue." }
                   
                  <CSVcost subscriber={this.props.subscriber} cost={this.props.cost}/> 
                </div>

                <div className="buttons-align-right">
                  <input type="button" className='button hollow' onClick={this.props.onCancel} value="Cancel"/>
                  <input type="button" className='button' onClick={this.props.approveAndSchedule} value="Approve and Schedule"/>
                </div>

              </div>
            </div>
          </div>
        );
    }
    return( <div></div> );



  }
}

class CSVcost extends React.Component {
  constructor(props) {
    super(props);
  }
  render() { 
    if( this.props.subscriber ) {
      return(
        <div className="bordered"><b>Scheduling this message will use {this.props.cost} credits.</b></div>
      )
    }
    else
    {
      return(
        <div className="bordered"><b>Testing is free -- this would typically use {this.props.cost} credits.<p/>
    
                Messages for guests are send <i>immediately</i> for testing purposes.  Guests may send up
                to three messages at a time.
            </b>
        
      </div>
      )
    }
  }
}


class SmsDemoUploader extends React.Component {


  constructor( props ) {
    super( props );
    this.state = {};
  } 


  // i think this is unused

  render() {

    return(
      <div className="channel cell small-12 medium-12 large-12">
        <div>SMS Reminder Demo</div>
        <div>

          Try the SMS Court Date Reminder:<p/>

          <ol>
            <li>Download <a href="#">this CSV file</a></li>
            <li>Edit the file if you like -- Feel free to change the columns.<p/>
            <li>Upload the CSV to us through the SMS Scheduler</li>
            </li>
              For demonstration purposes, we will send the first 3 entries to your SMS number <b>330-221-5752</b> 
              <br/>Each column is mapped to the following template.  Subscribers can add custom templates.

          </ol>

 

          <p/>

          
          

        </div>

 
      </div>
    ); 

  } 



}





class MenuBar extends React.Component {

  constructor( props ) {
    super( props );
    this.state = {};

  }
  render() {
    return( 
      <div className="cell small-12 menu-bar">
        <div className="flexbox">
          <div className="logo">Connect</div><div className="buttons">

            <ul className="menu">
              <Credits micro='true'/>
              <Settings changeRoute={this.props.changeRoute.bind(this)}/>
              <LogoutButton/>
            </ul>
            </div>
        </div> 
      </div>
     
    )
  }


}







export default App;
