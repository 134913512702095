import React from 'react';
import axios from 'axios';

export default class Login extends React.Component {

	constructor( props ) {

		super( props );

		this.state = { 
      username: "",
      password: "",
      badCredentials: false
    }

	}

  onSubmit() {

    if(  this.state.password == "" ||   this.state.username == "" )
      return;


    const account = {
      method: 'authenticate',
      username: this.state.username,
      password: this.state.password,
    }



    axios.post('/services/services.php', account )
      .then( res => {

        //if( res.data.success == true ) {

          if( res.data.trim() == "Authenticated" )
          {
            this.props.switchToDashboard();
          }
          else
            alert("Something unexpected happened :c");
          // window.location.href = res.data.destination;


      }, (error) => {
        this.setState( state=> ({badCredentials: true, message: 'Bad username or password.'}) );
        //alert("bad username or password?");
        /*
         this.setState( state => ({ loginError: "Bad username or password." } ) );
        */
      });

  } 

  onUsernameChange( e ) {
    this.setState( {username: e.target.value.trim() } );
  }

  onPasswordChange( e ) {
    this.setState( {password: e.target.value.trim() } );
  }

	render() {


		return( 
			<div className="login-form">
				<LoginTitle/> 
				<UserName onChange={this.onUsernameChange.bind(this)} val={this.state.username} />
        <Password onChange={this.onPasswordChange.bind(this)} val={this.state.password} />
        <LoginMessage message={this.state.message} show={this.state.badCredentials} />
        <LoginButton disabled={this.disabled} onSubmit={this.onSubmit.bind(this)}/>
        <SignupLink onSignupClick={this.props.onSignup}/>
        <AccountRecovery/>
			</div>


		);

	}

}

class LoginMessage extends React.Component {
  constructor( props ) {
    super(props);
  }
  render() {
    if( this.props.show == false ) 
      return( null );
    return( 
      <div className="error-text">{this.props.message}</div>
    )
  }
}
       

class AccountRecovery extends React.Component {
  constructor( props ) {
    super( props );
    this.state =  {
      showForm:0 
    }
    this.usernameRef = React.createRef();
  }

  showForm() {
    this.setState( {showForm: 1 } );

  }

  resetPassword() {
    var un = this.usernameRef.current.value.toLowerCase().trim();

    const account = {
      method: 'resetpassword',
      username: this.usernameRef.current.value 
    }

    axios.post('/services/services.php', account )
      .then(
        res => {
          this.setState( {showForm: 2 } );
         }
      );
  }

  render() {

   
    if( this.state.showForm === 0 ) { 
      return(

        <div className='forgot-password'>
          <a href='#' onClick={this.showForm.bind(this)}>Forgot Password</a>
        </div>

      )
    }

    if( this.state.showForm === 1 ) {
      return(
        <div>
          <h2>Password Recovery</h2>
          <label>Username:</label>
          <input ref={this.usernameRef} type='text' className='login-form-input'/>
          <input onClick={this.resetPassword.bind(this)} type='button' className='button sign-in-button' value='Reset password via SMS'/>
        </div>
      )
    }

    if( this.state.showForm === 2 ) {
      return(
        <div>
          <h2>Password Recovery</h2>
          <div className="fade bordered">
            We will message you a new password.  Please be sure to change it from your account settings
          </div>
        </div>
      )
    }

  }

}






class SignupLink extends React.Component {
  constructor( props ) {
    super(props);
  }

  render() {
    return(
      <div>
        <a href='#' onClick={this.props.onSignupClick} className='button sign-in-button hollow' >Create A Free Guest Account</a> 
      </div>
    )
  }
}
      


const LoginTitle = () => {
	return( 
		<div className="login-title">
			<h1>Nuncworks Connect</h1>
		</div>
	);
}

const LoginButton = props => {
  return(
    <div>
    <a className='button sign-in-button' href='#' onClick={props.onSubmit}>Sign In</a>
    </div>
  );
}

class Password extends React.Component {
  constructor( props) {
    super( props );
  }

  render() {
    return(
      <div>
        <label>Password:</label>
        <input onChange={this.props.onChange} value={this.props.val} className='login-form-input' type="password"/>
      </div>
    )
  }
}



class UserName extends React.Component {

	constructor( props ) {

		super( props );

	}

	render() {
		return(
			<div>
        <label>Username:</label>
 				<input onChange={this.props.onChange} className='login-form-input' type='text' value={this.props.val}/>
			</div>
		);
	}


}
