import React from 'react';
import axios from 'axios';
import { Grid, Cell, Button, Colors, Link, Tabs, TabItem, TabPanel, TabsContent } from 'react-foundation';
export default class AdminUI extends React.Component {


	constructor( props ) {

		super( props );

  }

  render() {
    return(
      <div>
        <ListUsers/>
      </div>
    );
  }

}



class ListUsers extends React.Component {

  constructor( props ) {
    
    super( props );

    this.state =  { users: [] };    

    this.update();
  }

  update = function() {

    const args = {
      method: 'getusers'
    }

    axios.post("services/services.php", args).then( res => {
        this.setState( {users: res.data.users } );
    }
    );

  

  }


  render() {
    return(

      <div>
        <h2>Users</h2>
      <Grid>
      { 
        this.state.users.map( (r) => 
          <User update={this.update.bind(this)} id={r.id} username={r.username} sms={r.sms} subscriber={r.subscriber} credits={r.credits} />
        )
      }
      </Grid>

      </div>

    )


  }


}

class User extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { showSubscriberForm: 0 };
  }

  addCredits = function( cr ) {

    const args = {
      method: 'adminaddcredits',
      id: this.props.id,
      credits: cr
      
    }

    axios.post("services/services.php", args).then( res => {
        this.setState( {users: res.data.users } );
    });
   
    this.props.update();
 
  }

  makeSubscriber = function() {
    const args = {
      method: 'setsubscriber',
      id: this.props.id
    }

    axios.post("services/services.php", args).then( res => {
        this.setState( {users: res.data.users } );
        this.props.update();
    }
    );

    
  }

  render() {
    return(
      <React.Fragment>
        <Cell small="1">{this.props.id}</Cell>
        <Cell small="2"><b>{this.props.username}</b></Cell>
        <Cell small="2">{this.props.sms}</Cell>
        <Cell small="2">{this.props.credits}</Cell>
        <Cell small="3"><Subscriber onAddCredits={this.addCredits.bind(this)} onMakeSubscriber={this.makeSubscriber.bind(this)} subscribes={this.props.subscriber} credits={this.props.credits}/></Cell>
        <Cell small="2"></Cell>

        {this.state.showSubscriberForm === 1 && <div>Set as subscriber</div> } 
      </React.Fragment>
    )
  }


}


class Subscriber extends React.Component {
  constructor( props ) {
    super( props );
  }

  promptForCredits = function() {
    var cr = prompt("How many credits do you want to add?");

    alert("Adding " + cr + " credits.");
    this.props.onAddCredits( cr );
  }


  render() {
    if( this.props.subscribes === 1 ) {
      return(
        <React.Fragment>
          <Link onClick={this.promptForCredits.bind(this)}>change credits</Link>
        </React.Fragment>
      )
    }
    
    return(
      <MakeSubscriberButton onMakeSubscriber={this.props.onMakeSubscriber} />
    )
  }

}

class MakeSubscriberButton extends React.Component {
  constructor( props ) {
    super( props );
    this.state = { confirmed :  0 };
  }

  confirm = function() {
    this.setState( { confirmed: 1 } );
  }

  render() {

      if( this.state.confirmed == 0 ) 
        return( 
          <input className="button" type="button" onClick={this.confirm.bind(this)} value="make subscriber"/>
        )
      if( this.state.confirmed == 1 )
        return( 
          <input className="button" type="button" onClick={this.props.onMakeSubscriber} value="Confirm"/>
        )
  
  }

}



